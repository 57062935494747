import React from 'react'
import { graphql } from 'gatsby'
import { Col, Container, Row, Section } from '../styles/common/Layout'
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { H1Box } from '../styles/common/Element';
import mq from '../styles/media-queries';
import styled from 'styled-components';

const CitiesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
`

const City = styled(Col)`
  margin-bottom: 30px;
  ${mq.md} {
    flex: 0 0 50%;
    max-width: 50%;
  }
  ${mq.lg} {
    flex: 0 0 33%;
    max-width: 33%;
  }
`

export default function PartnerPage({data}) {
  const cities = data.cities.nodes;
  const stripTrailingSlash = (str) => {
      const strin = str.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
      return strin.endsWith('/') ?
        strin.slice(0, -1) :
        strin;
  };
  return (
    <Layout bgColor={'black'}>
      <>
        <SEO
          title="Partnerstädte"
          keywords={['Partnerstädte',`Kulturgesichter`, `ORG`, `Alarmstufe Rot`, `Alarmstufe`]}
        />
        <Section id="partnerstaedte">
          <Container>
            <Row>
              <Col>
              <H1Box>Partnerstädte</H1Box>
                {cities &&
                  <CitiesWrapper>
                    {cities.map(city => (
                      <City key={city._id}>
                        <p>
                          <strong>{city.partnerCity}</strong><br />
                          {city.partnerCityUrl &&
                            <a rel="noreferrer" href={city.partnerCityUrl}>{stripTrailingSlash(city.partnerCityUrl)}</a>
                          }<br/>
                          {city.partnerCityInstagrammUrl &&
                            <a rel="noreferrer" href={city.partnerCityInstagrammUrl}>{stripTrailingSlash(city.partnerCityInstagrammUrl)}</a>
                          }
                          </p>
                      </City>
                    ))}
                  </CitiesWrapper>
                }
              </Col>
            </Row>
          </Container>
        </Section>
      </>
    </Layout>
  )
}

export const query = graphql`
  query PartnerCitySettingsQuery {
    cities: allSanityPartnerCity {
      nodes {
        _id
        partnerCity
        partnerCityUrl
        partnerCityInstagrammUrl
      }
    }
  }
`;
